<template>
  <BasicModal
    :title="info && info.id ? '编辑' : '新增'"
    width="600px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :footer="null"
    @cancel="cancel"
  >
    <div class="project-edit-content">
      <BasicForm
        class="project-edit-form"
        ref="formRef1"
        :labelCol="{ span: 6 }"
        :formItemsMap="formItemsMap1"
        v-model:formData="formData1"
      >
      <template #reportUrl="{form}">
        <div>
          <UploadButton showList :fileList="fileList" label="上传报告" fileType="file" :customFunc="customUpload" @uploadSuccess="uploadSuccess(form)" @remove="remove" >
          </UploadButton>
        </div>
      </template>
    </BasicForm>
      
    </div>
    <div class="actions">
      <a-button @click="cancel">取消</a-button>
      <a-button type="primary" :loading="loading" @click="ok"
        >保存</a-button
      >
    </div>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { message } from "ant-design-vue";
import { apiCarbonInfo } from "@/api/carbon.js";
import { apiFileUpload } from "@/api/common.js";
// import { validatePhone } from "@/components/basic/form/validator";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);
const fileList = ref([])


onMounted(async () => {
  await initFormItems();
  if (props.info) {
    initDetail();
  }
});

const initDetail = () => {
 for(let key in formData1){
  formData1[key] = props.info[key]
 }
 if(props.info.reportUrl){
  fileList.value = [{url: props.info.reportUrl,status: 'done', name: props.info.name}]
 }
};

const formItemsMap1 = reactive({
  subjectObj: {
    label: "碳排主体",
    prop: "subjectId",
    type: "select",
    required: true,
    options: [],
    span: 18,
    requiredMessage: "请选择",
  },
  time: {
    label: "碳排年份",
    prop: "year",
    type: "datePicker",
    picker: "year",
    format: "YYYY",
    required: true,
    span: 18,
    requiredMessage: "请选择",
  },
  verificationAgency: {
    label: "核查机构",
    prop: "verificationAgency",
    type: "input",
    required: true,
    span: 18,
    requiredMessage: "请选择",
  },
  reportSummary: {
    label: "核查摘要",
    prop: "reportSummary",
    type: "textarea",
    span: 18,
    requiredMessage: "请选择",
  },
  checkDate: {
    label: "核查时间",
    prop: "checkDate",
    type: "datePicker",
    format: "YYYY年M月D日",
    required: true,
    span: 18,
    requiredMessage: "请选择",
  },
  reportUrl:{
    label: "核查报告",
    prop: "reportUrl",
    slot: true,
    span: 18,
  }
});
const formData1 = reactive({
  subjectId:'',
  year: null,
  verificationAgency: '',
  reportSummary: "",
  checkDate: null,
  reportUrl:''
});

const initFormItems = async () => {

  apiCarbonInfo.panchaList().then(res=>{
    formItemsMap1.subjectObj.options = res.result.map(item=>{
      return {
        label: item.subjectName,
        value: item.id
      }
    })
  })

};
const customUpload = async (data) => {
  
  let res =  await apiFileUpload.file(data);
  console.log(333334444, data);
  formData1.reportUrl = res.result;
  let name = '';
  if (formData1.reportUrl) {
    let _index = formData1.reportUrl.indexOf('=file/')
    name = formData1.reportUrl.slice(_index+6)
  }
  fileList.value = [{url: formData1.reportUrl,status: 'done', name}]
  console.log(123,fileList.value)
};
const uploadSuccess = () => {
   
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef1.value.formRef.validateFields();
    const params = {
      subjectId:formData1.subjectId,
      year: formData1.year,
      verificationAgency: formData1.verificationAgency,
      reportSummary: formData1.reportSummary,
      checkDate: formData1.checkDate,
      reportUrl:formData1.reportUrl
    };
    if (props.info) {
      params.id = props.info.id;
    }
    if (formData1.reportUrl) {
      let _index = formData1.reportUrl.indexOf('=file/')
      params.name = formData1.reportUrl.slice(_index+6)
    }
    await apiCarbonInfo.reportSave(params);
    message.success(`${params.id ? "编辑成功" : "保存成功"}！`);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const remove = ()=>{
  formData1.reportUrl = ''
  fileList.value = []
}
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.actions {
  text-align: right;
  button {
    margin-left: 10px;
  }
}
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>
